import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

export default makeStyles({
  sideBar: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.25),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
});
