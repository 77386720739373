import React from 'react';
import { string, object } from 'prop-types';
import { Box, withWidth } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';

import theme from 'theme';
import useStyles from './styles';

const ALT = 'Total Loyalty, digital loyalty made easy for everyone.';

const BaseBanner = ({ width, alt = ALT, data }) => {
  const styles = useStyles();

  if (width === 'xs') {
    return (
      <Box boxShadow={2} className={styles.sideBar}>
        <GatsbyImage
            style={{
              marginLeft: theme.spacing(-1),
              marginRight: theme.spacing(-1),
              marginTop: theme.spacing(0.75),
            }}
            imgStyle={{ width: '100%', height: 'auto' }}
            image={data.xs.childImageSharp.gatsbyImageData}
            alt={ALT}
            />
        </Box>
    );
  }

  if (width === 'md') {
    return (
      <Box boxShadow={2} className={styles.sideBar}>
        <GatsbyImage
            style={{
              marginLeft: theme.spacing(-1),
              marginRight: theme.spacing(-1),
              marginTop: theme.spacing(-1),
            }}
            imgStyle={{ width: '100%', height: 'auto' }}
            image={data.md.childImageSharp.gatsbyImageData}
            alt={ALT}
            />
        </Box>
    );
  }

  if (width === 'sm') {
    return (
      <Box boxShadow={2} className={styles.sideBar}>
        <GatsbyImage
            style={{
              marginLeft: theme.spacing(-1),
              marginRight: theme.spacing(-1),
              marginTop: theme.spacing(-1),
            }}
            imgStyle={{ width: '100%', height: 'auto' }}
            image={data.sm.childImageSharp.gatsbyImageData}
            alt={ALT}
            />
        </Box>
    );
  }

  return (
    <Box boxShadow={2} className={styles.sideBar}>
     <GatsbyImage
       style={{
         marginLeft: theme.spacing(-1),
         marginRight: theme.spacing(-1),
         marginTop: theme.spacing(-1),
       }}
       imgStyle={{ width: '100%', height: 'auto' }}
       image={data.lg.childImageSharp.gatsbyImageData}
       alt={ALT}
       />
    </Box>
  );
};

BaseBanner.propTypes = {
  width: string.isRequired,
  alt: string,
  data: object.isRequired,
};

export default withWidth()(BaseBanner);

export const SimpleBanner = ({ alt = ALT, data }) => {
  const styles = useStyles();

  return (
    <Box boxShadow={2} className={styles.sideBar}>
      <GatsbyImage
        style={{
          marginLeft: theme.spacing(-1),
          marginRight: theme.spacing(-1),
          marginTop: theme.spacing(-1),
        }}
        imgStyle={{ width: '100%', height: 'auto' }}
        image={data.file.childImageSharp.gatsbyImageData}
        alt={ALT}
        />
      </Box>
  );
} 

SimpleBanner.propTypes = {
  alt: string,
  data: object.isRequired,
};
